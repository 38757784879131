import './App.css';
import ControlCheck from './ControlCheck';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Select from 'react-select'
import ground from './ground'
import naval from './naval'
import airstrike from './airstrike'
import React, { useState } from 'react';

import missile from './missile'
import nuke from './nuke'
import Event from './Event';


export default function Nation(props) {

    let attordef = props.attordef
    let war = props.war
    let nat = war[attordef]
    let not_attordef;
    if (props.attordef === "attacker") { not_attordef = "defender" } else {
        not_attordef = "attacker"
    }
    return (<>
        < span className='bold' > {nat.name}</span >
        <p>Soldiers: {nat.soldiers}</p>
        <p>Tanks: {nat.tanks}</p>
        <p>Aircraft: {nat.aircraft}</p>
        <p>Ships: {nat.ships}</p>
        <ControlCheck comp={"ground_control"} war={war} attordef={attordef} />
        <ControlCheck comp={"air_control"} war={war} attordef={attordef} />
        <ControlCheck comp={"sea_control"} war={war} attordef={not_attordef} />

        <span className='bold'> Resistance</span>
        <ResistanceBar res={nat.resistance} />


        <span className="bold">Military Action Points Available</span>
        <p>{nat.maps}</p>

        <AttackDropDown war={war} setwar={props.setwar} attordef={attordef} display={nat.playable} addEvent={props.addEvent} />
    </>
    );
}

function ResistanceBar(props) {
    let res = props.res
    return <ProgressBar striped animated variant="success" now={res} label={res + "/100"} className='progress' />;
}


function AttackDropDown(props) {
    const [airTargetting, setTargetting] = useState("aircraft");

    if (props.display !== false) {
        let setwar = props.setwar
        let war = props.war
        let attordef = props.attordef

        const handleChange = (selectedOption) => {

            let atack_type = selectedOption.value
            if (atack_type === "ground") {
                setwar(ground(war, attordef, props.addEvent))

            }
            else if (atack_type === "naval") {
                setwar(naval(war, attordef, props.addEvent))
            }
            else if (atack_type === "air") {
                setwar(airstrike(war, attordef, airTargetting, props.addEvent))
            }
            else if (atack_type === "missile") {
                setwar(missile(war, attordef, props.addEvent))
            }
            else if (atack_type === "nuke") {
                setwar(nuke(war, attordef, props.addEvent))
            }
        }
        let disabled = false
        if (war.winner !== null) {
            disabled = true
        }
        const changeTargetting = (selected) => {
            setTargetting(selected.value)
        }
        return (
            <>
                <Select onChange={handleChange} isDisabled={disabled}
                    options={[
                        { value: 'ground', label: "Ground Battle(3)" },
                        { value: 'air', label: "Airstrike(4) " },
                        { value: 'naval', label: "Naval Battle (4)" },
                        { value: 'missile', label: "Launch Missile (8)" },
                        { value: 'nuke', label: "Nuclear Attack (12)" },
                    ]} />
                <Select onChange={changeTargetting} isDisabled={disabled}
                    options={[
                        { value: 'soilders', label: "Target Soilders" },
                        { value: 'tanks', label: "Target Tanks" },
                        { value: 'aircraft', label: "Target Aircraft" },
                        { value: 'ships', label: "Target Ships" },
                    ]} />


            </>
        )
    }
}
