import ground from "./ground";
import airstrike from "./airstrike";
import naval from "./naval";
import nuke from "./nuke";
export default function ai_logic(war, attordef, addEvent) {
    let not_attordef;
    if (attordef === 'attacker') {
        not_attordef
            = "defender"
    } else {
        not_attordef
            = "attacker"
    }

    if (war[attordef].maps >= 4 && war[attordef].ships >= war[not_attordef].ships) {
        war = naval(war, attordef, addEvent)
    }
    else if (war[attordef].maps >= 3 && (war[attordef].soldiers * 1.75 + war[attordef].tanks * 40) >= (war[not_attordef].soldiers * 1.75 + war[not_attordef].tanks * 40)) {
        war = ground(war, attordef, addEvent)
    }
    else if (war[attordef].maps >= 4 && war[attordef].aircraft >= war[not_attordef].aircraft) {
        war = airstrike(war, attordef, addEvent)
    }
    else if (war[attordef].maps >= 12) {
        war = nuke(war, attordef, addEvent)
    }

    return { ...war }
}