import CheckWin from './CheckWin'
import ai_logic from './ai_logic';
import Event from './Event';

export default function airstrike(war, striker, targeting, addEvent) {
    let not_striker;

    if (striker === 'attacker') { not_striker = "defender" } else {
        not_striker = "attacker"
    }
    if (war[striker].maps >= 4) {
        war[striker].maps -= 4

        let attacker_value = war[striker].aircraft * 3
        let defender_value = war[not_striker].aircraft * 3
        let attwins = 0
        let att_planes_lost = 0
        let def_planes_lost = 0
        for (let i = 0; i < 3; i++) {
            let attk_scre = attacker_value * (Math.random() * 0.6 + 0.4)
            let dfder_scre = defender_value * (Math.random() * 0.6 + 0.4)
            if (attk_scre >= dfder_scre) {
                attwins += 1
            }
            if (targeting === "aircraft") {
                att_planes_lost += dfder_scre * 0.01
                def_planes_lost += attk_scre * 0.018337
            }
            else {
                att_planes_lost += dfder_scre * 0.015385
                def_planes_lost += attk_scre * 0.009091
            }
        }
        let troop_mod = 0

        if (attwins === 3) {
            war[not_striker].resistance = Math.max(0, war[not_striker].resistance - 12)
            war.air_control = striker
            troop_mod = 1
        }
        else if (attwins === 2) {
            war[not_striker].resistance = Math.max(0, war[not_striker].resistance - 9)
            if (war.air_control !== striker) {
                war.air_control = null
            }
            troop_mod = 0.7

        }
        else if (attwins === 1) {
            war[not_striker].resistance = Math.max(0, war[not_striker].resistance - 6)
            if (war.air_control !== striker) {
                war.air_control = null
            }
            troop_mod = 0.4

        }
        let other_lost = 0
        let other_text = ""
        if (targeting === "soilders") {
            other_lost = Math.round((war[striker].aircraft - war[not_striker].aircraft * 0.5)
                * 35
                * ((Math.random() * 0.2) + 0.85) * troop_mod)
            other_text = "and " + Math.min(other_lost, war[not_striker].soldiers) + " Soilders"
            war[not_striker].soldiers = Math.max(0, war[not_striker].soldiers - other_lost)
        }
        else if (targeting === "tanks") {
            other_lost = Math.round((war[striker].aircraft - war[not_striker].aircraft * 0.5)
                * 1.25
                * ((Math.random() * 0.2) + 0.85) * troop_mod)
            other_text = "and " + Math.min(other_lost, war[not_striker].tanks) + " Tanks"
            war[not_striker].tanks = Math.max(0, war[not_striker].tanks - other_lost)
        }
        else if (targeting === "ships") {
            other_lost = Math.round((war[striker].aircraft - war[not_striker].aircraft * 0.5)
                * 0.0285
                * ((Math.random() * 0.2) + 0.85) * troop_mod)
            other_text = "and " + Math.min(other_lost, war[not_striker].ships) + " Ships"
            war[not_striker].ships = Math.max(0, war[not_striker].ships - other_lost)
        }
        war[striker].aircraft = Math.max(0, Math.round(war[striker].aircraft - att_planes_lost))
        war[not_striker].aircraft = Math.max(0, Math.round(war[not_striker].aircraft - def_planes_lost))
        let tone = "good"
        if (war[striker].playable === false) {
            tone = "bad"
        }

        addEvent(<Event tone={tone} title={war[striker].name + " did a airstrike"} text={war[striker].name + " lost " + Math.round(att_planes_lost) + " planes \n " + war[not_striker].name + " lost " + Math.round(def_planes_lost) + " planes " + other_text} />)
    }
    else {
        addEvent(<Event tone={"neutral"} title={war[striker].name + " dont have enough maps for that"} text={""} />)
    }

    war = ai_logic(war, "defender", addEvent)

    war.winner = CheckWin(war)
    return { ...war }
}


