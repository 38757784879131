import './App.css';
import War from './War';
import InputNumber from 'react-input-number';

import React, { useState } from 'react';


function Ai(props) {

    const [components, setComponents] = useState([]);
    const [cities, setCities] = useState(10);

    function handle() {
        setComponents([...components, null])
    }

    return (
        <div className='center'>
            <span className="bold">Fight against an AI</span>
            <InputNumber min={1} max={50} step={1} value={cities} onChange={setCities} enableMobileNumericKeyboard />
            <button onClick={handle}> Start War</button>

            {components.map((item, i) => (<War key={i} cities={cities} playable={[true, false]} />))}
        </div>
    );

}








export default Ai;
