
export default function CheckWin(war) {
    if (war.attacker.resistance === 0) {
        return "defender"
    }
    else if (war.defender.resistance === 0) {
        return "attacker"
    }
    else if (war.remaining_turns === 0) {
        return "draw"
    }
    return null

}