import CheckWin from './CheckWin'
import ai_logic from './ai_logic';
import Event from './Event';

export default function naval(war, striker, addEvent) {

    let not_striker;

    if (striker === 'attacker') { not_striker = "defender" } else {
        not_striker = "attacker"
    }
    if (war[striker].maps >= 4) {
        war[striker].maps -= 4
        let attacker_value = war[striker].ships * 4
        let defender_value = war[not_striker].ships * 4
        let attwins = 0
        let att_ships_lost = 0
        let def_ships_lost = 0
        for (let i = 0; i < 3; i++) {
            let attk_scre = attacker_value * ((Math.random() * 0.6) + 0.4)

            let dfder_scre = defender_value * ((Math.random() * 0.6) + 0.4)
            if (attk_scre >= dfder_scre) {
                attwins += 1
            }
            att_ships_lost += dfder_scre * 0.01375
            def_ships_lost += attk_scre * 0.01375
        }

        if (attwins === 3) {
            war[not_striker].resistance = Math.max(0, war[not_striker].resistance - 14)

            war.sea_control = striker
        }
        else if (attwins === 2) {
            war[not_striker].resistance = Math.max(0, war[not_striker].resistance - 11)
            if (war.sea_control !== striker) {
                war.sea_control = null
            }

        }
        else if (attwins === 1) {
            war[not_striker].resistance = Math.max(0, war[not_striker].resistance - 8)
            if (war.sea_control !== striker) {
                war.sea_control = null
            }
        }
        war[striker].ships = Math.max(0, Math.round(war[striker].ships - att_ships_lost))
        war[not_striker].ships = Math.max(0, Math.round(war[not_striker].ships - def_ships_lost))
        let tone = "good"
        if (war[striker].playable === false) {
            tone = "bad"
        }

        addEvent(<Event tone={tone} title={war[striker].name + " did a naval attack"} text={war[striker].name + " lost " + Math.round(att_ships_lost) + " ships \n " + war[not_striker].name + " lost " + Math.round(def_ships_lost) + " ships"} />)
    }
    else {
        addEvent(<Event tone={"neutral"} title={war[striker].name + " dont have enough maps for that"} text={""} />)
    }

    war = ai_logic(war, "defender", addEvent)

    war.winner = CheckWin(war)
    return { ...war }
}