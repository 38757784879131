import CheckWin from './CheckWin'
import Event from './Event';
export default function nuke(war, striker, addEvent) {
    let not_striker;

    if (striker === 'attacker') { not_striker = "defender" } else {
        not_striker = "attacker"
    }
    if (war[striker].maps >= 12) {
        war[striker].maps -= 12

        war[not_striker].resistance = Math.max(0, war[not_striker].resistance - 25)

        let tone = "good"
        if (war[striker].playable === false) {
            tone = "bad"
        }
        addEvent(<Event tone={tone} title={war[striker].name + " dropped a nuke"} text={""} />)
    }
    else {
        addEvent(<Event tone={"neutral"} title={war[striker].name + " dont have enough maps for that"} text={""} />)
    }

    war.winner = CheckWin(war)
    return { ...war }
}