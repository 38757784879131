import CheckWin from './CheckWin'
import ai_logic from './ai_logic';
import Event from './Event';

export default function ground(war, striker, addEvent) {

    let not_striker;

    if (striker === 'attacker') { not_striker = "defender" } else {
        not_striker = "attacker"
    }
    if (war[striker].maps >= 3) {
        war[striker].maps -= 3

        let attacker_soilder_value = war[striker].soldiers * 1.75
        let defender_soilder_value = war[not_striker].soldiers * 1.75
        let attacker_tank_value = war[striker].tanks * 40
        let defender_tank_value = war[not_striker].tanks * 40
        if (war.air_control === not_striker) {
            attacker_tank_value *= 0.5
        }
        else if (war.air_control === striker) {
            defender_tank_value *= 0.5
        }
        let attwins = 0
        let att_soilders_lost = 0
        let def_soilders_lost = 0
        let att_tanks_lost = 0
        let def_tanks_lost = 0

        for (let i = 0; i < 3; i++) {

            let attk_soilder_scre = attacker_soilder_value * ((Math.random() * 0.6) + 0.4)
            let dfder_soilder_scre = defender_soilder_value * ((Math.random() * 0.6) + 0.4)
            let attacker_tank_scre = attacker_tank_value * ((Math.random() * 0.6) + 0.4)
            let defender_tank_scre = defender_tank_value * ((Math.random() * 0.6) + 0.4)
            let attk_scre = attk_soilder_scre + attacker_tank_scre
            let dfder_scre = dfder_soilder_scre + defender_tank_scre
            if (attk_scre > dfder_scre) {
                attwins += 1
                att_tanks_lost += (dfder_soilder_scre * 0.0004060606) + (
                    defender_tank_scre * 0.00066666666
                )
                def_tanks_lost += (attk_soilder_scre * 0.00043225806) + (
                    attacker_tank_scre * 0.00070967741
                )
            }
            else {
                att_tanks_lost += (dfder_soilder_scre * 0.00043225806) + (
                    defender_tank_scre * 0.00070967741
                )
                def_tanks_lost += (attk_soilder_scre * 0.0004060606) + (
                    attacker_tank_scre * 0.00066666666
                )
            }
            att_soilders_lost += (dfder_soilder_scre * 0.0084) + (
                defender_tank_scre * 0.0092
            )
            def_soilders_lost += (attk_soilder_scre * 0.0084) + (
                attacker_tank_scre * 0.0092
            )
        }
        if (war.ground_control === striker) {
            war[not_striker].aircraft = Math.max(
                Math.round(
                    war[not_striker].aircraft
                    - (
                        0.0025
                        * attwins
                        * war[striker].tanks
                    ),
                ),
                0,)
        }
        war[striker].soldiers = Math.max(0, Math.round(war[striker].soldiers - att_soilders_lost))
        war[striker].tanks = Math.max(0, Math.round(war[striker].tanks - att_tanks_lost))
        war[not_striker].soldiers = Math.max(0, Math.round(war[not_striker].soldiers - def_soilders_lost))
        war[not_striker].tanks = Math.max(0, Math.round(war[not_striker].tanks - def_tanks_lost))

        if (attwins === 3) {
            war[not_striker].resistance = Math.max(0, war[not_striker].resistance - 10)
            war.ground_control = striker
        }
        else if (attwins === 2) {
            war[not_striker].resistance = Math.max(0, war[not_striker].resistance - 7)
            if (war.ground_control !== striker) {
                war.ground_control = null
            }
        }
        else if (attwins === 1) {
            war[not_striker].resistance = Math.max(0, war[not_striker].resistance - 4)
            if (war.ground_control !== striker) {
                war.ground_control = null
            }
        }
        let tone = "good"
        if (war[striker].playable === false) {
            tone = "bad"
        }

        addEvent(<Event tone={tone} title={war[striker].name + " did a ground attack"} text={war[striker].name + " lost " + Math.round(att_soilders_lost) + " soilders and " + Math.round(att_tanks_lost)
            + " tanks " + war[not_striker].name + " lost " + Math.round(def_soilders_lost) + " soilders and " + Math.round(def_tanks_lost)
            + " tanks "
        } />)
    }
    else {
        addEvent(<Event tone={"neutral"} title={war[striker].name + " dont have enough maps for that"} text={""} />)
    }

    war = ai_logic(war, "defender", addEvent)
    war.winner = CheckWin(war)
    return { ...war }
}
