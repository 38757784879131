export default function Home() {
    return (
        <>
            <div id="sidebar">
                <nav>
                    <ul>
                        <li>
                            <a href={`/Ai`}>AI lol</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </>
    );
}