export default function ControlCheck(props) {
    let comp = props.comp
    let war = props.war
    let attordef = props.attordef
    if (war[comp] === attordef) {
        if (comp === "ground_control") {
            return (
                < img alt="" src="https://politicsandwar.com/img/icons/16/building_error.png" />
            )
        }
        if (comp === "air_control") {
            return (
                < img alt="" src="https://politicsandwar.com/img/icons/16/plane.png" />
            )
        }
        if (comp === "sea_control") {
            return (
                < img alt="" src="https://politicsandwar.com/img/icons/16/cruise_ship.png" />
            )
        }

    }
}