import './App.css';
import Nation from './Nation';
import CheckWin from './CheckWin'
import ai_logic from './ai_logic';
import Event from './Event';
import React, { useState } from 'react';


function War(props) {
    const [events, addEventBase] = useState([<Event tone={"neutral"} title={"The War Started!"} text={""}></Event>]);
    function addEvent(event) {
        addEventBase([event, ...events])
    }

    let your_cities = 8
    let cities = props.cities
    let [war, setwar] = useState({
        attacker: {
            name: "You", resistance: 100, maps: 6, soldiers: 15000 * your_cities
            , tanks: 250 * your_cities, aircraft: 75 * your_cities, ships: 15 * your_cities
        },
        defender: {
            name: "The AI", playable: false, resistance: 100, maps: 6, soldiers: 15000 * cities
            , tanks: 250 * cities, aircraft: 75 * cities, ships: 15 * cities
        },
        remaining_turns: 60,
        ground_control: null,
        sea_control: null,
        air_control: null,
        winner: null
    })
    war = ai_logic(war, "defender", addEvent)
    return (<div className="War">
        <div className='row'>
            <div className='third'>
                <span className="bold">Aggressor</span>
                <br />
                <Nation war={war} setwar={setwar} attordef={"attacker"} addEvent={addEvent} />
                <br />
            </div>
            <div className='third'>
                <span className="bold">Defender</span>
                <br />
                <Nation war={war} setwar={setwar} attordef={"defender"} addEvent={addEvent} />
                <br />
            </div>
            <div className='third'>
                <span className="bold">Events feed</span>
                <br />
                {events.map((item, i) => (item))}

                <br />
            </div>


        </div>
        <div className='center'>
            <span className="bold">Turns Remaining</span>
            <p>{war.remaining_turns}</p>
            <Turnbutton war={war} setwar={setwar} addEvent={addEvent}> </Turnbutton>
        </div>

        <Winner war={war} />
    </div >

    );
}
function Winner(props) {
    let war = props.war
    if (war.winner === "attacker" || war.winner === "defender") {
        return (<h1>{war[war.winner].name} Has Won!</h1>)
    }
    if (war.winner === "draw") {
        return (<h1>Peace between {war["attacker"].name} and {war["defender"].name} Has Been Achived</h1>)
    }
    return (<></>)
}


function Turnbutton(props) {
    let war = props.war

    let disabled = false
    if (war.winner !== null) {
        disabled = true
    }
    return (<>
        <button disabled={disabled} onClick={() => props.setwar(turn(war, props.addEvent))}>
            Turn Change
        </button></>)
}

function turn(war, addEvent) {

    if (war.attacker.maps < 12) {
        war.attacker.maps += 1
    }
    if (war.defender.maps < 12) {
        war.defender.maps += 1
    }
    war.remaining_turns = Math.max(0, war.remaining_turns - 1)
    war.winner = CheckWin(war)
    war = ai_logic(war, "defender", addEvent)
    return { ...war }

}








export default War;
